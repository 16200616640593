<template>
    <div class="main">
        <div class="banner">
            <div class="banner-overlay"></div>
             <h1>LES AVANTAGES <br />DES ÉVÉNEMENTS VIRTUELS</h1>
        </div>
        <section class="title-section">
            <div class="row">
                <h2 class="gradiant-text">AUDIENCE MAXIMISEE, STATISTIQUES DETAILLEES<br />DES VISITEURS, SOUPLESSE & RAPIDITE</h2>
            </div>
            <div class="row">
                <p>Les événements virtuels ne remplaceront pas les événements physiques mais peuvent être très complémentaires et mêmes plus performants sur certains aspects !</p>
            </div>
        </section>
        <section class="masonry-container">
            <div class="sep sep-top"></div>
            <div class="sep sep-bottom"></div>
            <div class="row">
                <div class="masonry">

                    <!-- col 1 -->

                    <div class="item violet">
                        <p><strong>Augmentation de la notoriété et de la visibilité de l’événement grâce à la portée inégalée du Web.</strong><br />Combien de participants potentiels ne peuvent pas se rendre à un événement en présentiel en raison d’un emploi du temps trop chargé ou d’un manque de budget ?<br />
                            Laissez-les désormais interagir avec vous n’importe où, n’importe quand et sans frais de déplacement !</p>
                            <ul>
                                <li>Tout appareil connecté à Internet</li>
                                <li>Tout navigateur Internet (Hors Internet Explorer désormais trop obsolète)</li>
                                <li>Partout dans le monde</li>
                            </ul>
                    </div>

                    <div class="item">
                        <p><strong>Hausse de la fréquentation des visiteurs du fait de l’efficacité du temps consacré et de l’accessibilité à moindre coût</strong></p>
                            <ul>
                                <li>Il suffit d'une minute à un participant pour s’inscrire avant de pouvoir évoluer au sein d'un événement digital : visiter les stands virtuels des exposants, engager des conversations, suivre des conférences en direct ou préenregistrées, etc.</li>
                            </ul>
                    </div>

                    <div class="item blue">
                        <div class="item-img">
                            <img src="./../assets/avantage1.png" alt="avantage 1" />
                        </div>
                    </div>

                    <div class="item">
                        <p><strong>Possibilité de poursuivre le salon virtuel après la clôture du salon physique ou basculer sur un modèle permanent qui, couplé à du marketing digital, peut devenir un véritable générateur de leads commerciaux pour les exposants</strong></p>
                    </div>


                    <div class="item blue">
                        <p><strong>Vecteur efficace d’information et de veille technologique</strong></p>
                    </div>


                    <!-- col 2 -->

                    <div class="item">
                        <p><strong>Alternative fiable et pertinente en cas d’impossibilité d’organiser l’événement physique</strong></p>
                            <ul>
                                <li>Contrainte sanitaire de type COVID 19, etc.</li>
                                <li>Problème climatique</li>
                                <li>Evolution géopolitique</li>
                            </ul>
                    </div>

                    <div class="item violet">
                        <p><strong>Efficace et économique !</strong> </p>
                        <p>Les événements digitaux permettent à toutes les parties de réaliser des économies, que ce soit les organisateurs, les exposants comme les visiteurs ! Tant de temps et d’argent sont consacrés à la préparation et à la participation à un événement ou salon physique !<br /> En revanche, la participation virtuelle est, quant à elle, facile, pratique et efficace !</p>
                    </div>

                    <div class="item">
                        <div class="item-img">
                            <img src="./../assets/avantage3.png" alt="avantage 3" />
                        </div>
                    </div>

                    <div class="item blue">
                        <p><strong>Mise à disposition de statistiques de suivi pour permettre aux exposants et aux organisateurs de mieux mesurer leur efficacité et le ROI de leur participation à un événement</strong></p>
                    </div>

                    <div class="item violet">
                        <p><strong>Outil de tracking pour permettre à l’exposant de récupérer une base de données très qualifiée des personnes qui sont passées sur son stand ou de leads de qualité !</strong></p>
                            <ul>
                                <li>Qui sont-ils ?</li>
                                <li>Quels produits ou services ont-ils regardé ?</li>
                                <li>Quels fichiers media ont-ils visionnés ou téléchargés ?</li>
                            </ul>
                    </div>

                    <!-- col 3 -->

                    <div class="item blue">
                        <p><strong>Souplesse très forte sur le nombre d’exposants, au niveau de l’organisation et du calendrier.</strong><br /> Par ailleurs, le lieu n’est plus un problème et l’espace virtuel n’a pas de limite de capacité ni de taille.</p>
                            <ul>
                                <li>Un nombre de participants en ligne illimité</li>
                                <li>Un nombre de participants en ligne illimité</li>
                                <li>Un nombre illimité de conversations en direct</li>
                            </ul>
                    </div>



                    <div class="item violet">
                        <p><strong>Limitation et diminution de l’empreinte Carbone des salons et congrès professionnels</strong></p>
                            <ul>
                                <li>Un accès direct et instantané qui remplace de longs trajets polluants</li>
                                <li>Des fichiers media disponibles en ligne plutôt que des brochures, flyers et dépliants qui finissent bien souvent par remplir les poubelles</li>
                            </ul>
                    </div>

                    <div class="item blue">
                        <div class="item-img">
                            <img src="./../assets/avantage2.png" alt="avantage 2" />
                        </div>
                    </div>

                    <div class="item violet">
                        <p><strong>Personnalisation totale</strong></p>
                            <ul>
                                <li>Page d’inscription personnalisée à l’image de votre marque</li>
                                <li>Salon virtuel selon vos normes graphiques</li>
                                <li>Différents modèles de stand virtuel offerts et plusieurs autres fonctionnalités disponibles</li>
                            </ul>
                    </div>

                    <div class="item">
                        <p><strong>Présentation des produits via des technologies nouvelle génération et interactives</strong></p>
                            <ul>
                                <li>3D Temps Réel</li>
                                <li>Configurateurs interactifs</li>
                            </ul>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>


<script>
export default {
    name : 'Avantages'
}
</script>

<style lang="scss" scoped>
    .banner {
        background-image : url('./../assets/bg-avantages.jpg');
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
    }

    .title-section .row {
        width: $boxWidth;
        max-width: 96%;  
        justify-content: center; 
        margin: 40px auto;
    }

    .title-section .row h2 {
        max-width: 80%;
        text-transform: uppercase;
    }

    .title-section p {
        text-align: center;
        font-size:20px;
        margin-top: -20px;
        max-width: 80%;
    }

    .sep-top, .sep-bottom {
        background: #fff;
    }

    .masonry { 
        column-count: 3;
        column-gap: 1em;
        position: relative;
        z-index: 99;
    }

    .masonry-container {
        display: flex;
        justify-content: center;
        background: $backgroundGray;
        position: relative;
    }

    .masonry-container .row {
        width: $boxWidth;
        max-width: 96%; 
    }

    .item { 
        display: inline-block;
        margin: 0 0 1em;
        width: calc(100% - 40px);
        background-color: #5f79b7;
        padding:20px;
        color:#fff;
    }

    .item p, .item li {
        color:#fff;
        line-height: 25px;
    }

    .item p {
        font-weight:400;
    }

    .item .item-img {
        width: 100%;
        display: flex;
        justify-content:center;
    }

    .item.blue {
        background-color: #2b94d0;
    }

    .item.violet {
        background-color:#a33790;
    }

    @media screen and (max-width: 1200px) {
        .masonry { 
            column-count: 2;
        }
    }

    
    @media screen and (max-width: 768px) {
        .masonry { 
            column-count: 1;
        }
    }

</style>